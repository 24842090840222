/* Track */
.Track {
	position: relative;
	display: flex;
	flex: 1;
	font-size: 0.875em;
	border-bottom: 1px solid var(--lightgray);
}
.Layout--theme-dark .Track {
	border-bottom-color: var(--darkgray);
}
.Track-main {
	flex: 1;
	align-self: center;
	padding: 0.6em 1em 0.6em 2em;
}
.Track menu {
	visibility: hidden;
	position: absolute;
	top: 0.3em;
	padding: 0;
	right: 0;
	margin: 0;
	/* margin: 0.4rem 0rem 0 auto; */
}
.Track.can-edit .Track-main:hover,
.Track.can-edit .Track-main:focus {
	background: var(--almost-bg);
}

.Track.can-edit:hover menu,
.Track.can-edit:focus menu {
	visibility: visible;
}

.Track h4 {
	font-weight: normal;
	margin: 0;
	word-wrap: anywhere;
}
.Track p {
	margin: 0;
}
.Track-tags {
	margin-top: 0.5rem;
	margin-left: 1em;
	margin-right: 1em;
}
.Track form {
	flex: 1;
	margin: 1rem;
}
.Track.is-editing {
	padding-left: 1em;
	background: var(--white);
}
.Layout--theme-dark .Track.is-editing {
	background: var(--almost-bg);
}

.Tracks > * {
	display: flex;
	flex: 1;
}
