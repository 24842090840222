/* default light */
.Layout {
	--bg-color: var(--white);
	--almost-bg: hsl(0, 0%, 94%);
	--text-color: var(--black);
	--link-color: var(--theme);
}

/* dark variation */
.Layout--theme-dark {
	/* --theme: var(--orange); */
	/* --bg-color: var(--black); */
	/* --almost-bg: hsl(0, 0%, 20%); */
	/* --text-color: hsl(180deg 7% 90%); */
	/* --link-color: var(--text-color); */
	/* --lightgray: hsl(0, 0%, 14%); */
}

/* Layout padding/margin behavior */
*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	margin: 0;
}

/* make .Layout the default element to style from */
html, /* native root elements */
body,
#root, /* react root element */
.Layout /* our root element for layouting */ {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100%;
}

/* Themes */
html,
.Layout {
	font-family: segoe ui, system-ui, -apple-system, sans-serif;
}
.Layout {
	font-size: 16px;
	/* clamp(min, target, max) */
	font-size: clamp(16px, 0.875rem + 0.25vw, 26px);
	line-height: 1.5;
	scroll-behavior: smooth;
	padding: 1em 1.5em 0;
	width: 100%;
	color: var(--text-color);
	transition: background-color ease-in-out 100ms, color ease-in-out 100ms;
}

@media (max-width: 400px) {
	.Layout {
		padding-left: 1em;
		padding-right: 1em;
	}
}

/* layout elements */
.Layout-main section {
	margin-bottom: 1.5em;
}

.Layout-footer {
	margin-top: auto;
	/* position: fixed; */
	/* bottom: 0; */
	/* left: 0; */
	/* right: 0; */
	/* z-index: 1; */
	border-top: 1px solid var(--darkgray);
	background: var(--bg-color);
}


.Layout ol {
	text-align: left;
}

/* migration only */
r4-migration-step {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background-color: var(--bg-color);
		border-radius: 0.3rem;
		padding: 1rem;
		margin: 0.5rem;

}
r4-migration-step h3 {
	padding: 1rem;
}
r4-migration-step p {
	margin-bottom: 0;
}
