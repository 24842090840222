.Tag {
	border-radius: 3rem;
	line-height: 1;
	font-size: 0.75rem;
	padding: 0px 0.5em 3px 0.5em;
	display: inline-flex;
	overflow: hidden;
	border: 1px solid;
	background-color: var(--bg-color);
	border-color: var(--gray);
}
