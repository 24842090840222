.FormRow,
.FormStacked {
	display: flex;
	gap: 1em;
}

.FormRow {
	flex-flow: row;
	align-items: flex-end;
}

.FormStacked {
	flex-flow: column nowrap;
	align-items: flex-start;
}
.FormStacked label {
	flex: 1;
}
