/* Default, closed state */
.CommandMenu-wrapper {
	position: relative;
	margin: 0 auto auto;
	padding-top: 0.5em;
	padding-bottom: 2em;
	background-color: var(--white);
}

/* Open state */
.CommandMenu[aria-expanded='true'] {
	display: block;
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10vh 1rem 1rem;
	/* background: hsla(0, 16%, 30%, 0.05); */
	display: flex;
	margin: 0 auto auto;
}
.CommandMenu[aria-expanded='true'] .CommandMenu-wrapper {
	width: 100%;
	max-width: 640px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 70px;
}
.Layout--theme-dark .CommandMenu-wrapper {
	background: var(--almost-bg);
}

/* The search input */
.CommandMenu input {
	max-width: none;
	font-size: x-large;
	padding: 0.7em 1em;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid hsla(0, 20%, 50%, 0.1);
}
.CommandMenu input:focus {
	outline: none;
}

/* The menu list */
.CommandMenu [role='menu'] {
	margin: 0;
	padding: 0;
	list-style: none;
	max-height: 25em;
	overflow: auto;
}
.CommandMenu [role='menu'] > li {
	list-style: none;
}
.CommandMenu [role='menuitem'] {
	display: flex;
	font-size: 1em;
	width: 100%;
	text-align: left;
	padding: 0.7em 1.5em 0.6em;
	background-color: transparent;
	border: 0;
	border-left: 2px solid transparent;
}
.CommandMenu kbd {
	margin-left: auto;
}

/* Selected menu item */
.CommandMenu [role='menuitem']:focus {
	outline: none;
}
.CommandMenu [role='menuitem'][aria-current='true'] {
	outline: none;
	background-color: var(--almost-bg);
	color: var(--text-color);
	border-left: 2px solid var(--theme);
}
.Layout--theme-dark .CommandMenu [role='menuitem'][aria-current='true'] {
	background-color: var(--darkgray);
}

/* The footer */
.CommandMenu legend {
	position: absolute;
	bottom: 0.5em;
	right: 0.7em;
	color: var(--gray);
}
