/* global html parameters */
[danger] {
	color: var(--red);
}
[danger][disabled] {
	background-color: var(--orange);
}

/* Links */
a {
	text-decoration: underline;
	text-decoration-thickness: max(1px, 0.1em);
	text-underline-offset: 0.1em;
}
a,
a:visited {
	color: var(--link-color);
}

a,
summary,
button:not([disabled]) {
	cursor: pointer;
}

/* Focus state (kbd TAB navigation) uniformization */
a:focus,
button:focus,
select:focus {
	outline: 1px solid var(--theme);
	transition: outline-color 300ms ease-in-out;
}
a:focus,
button:focus,
select:focus-visible {
	outline: 1px solid var(--theme);
}
select:focus-within {
}

/* Headlines */
h1 select,
h2 select,
h3 select {
	max-width: 10em;
	margin-left: 0.5rem;
}
/* 48, 36, 24, 19, 16*/
h1 {
	font-size: 1.5em;
	font-weight: 700;
	margin-bottom: 1rem;
}
h2 {
	font-size: 1.25em;
	font-weight: 700;
	margin-bottom: 1rem;
}
h3 {
	font-size: 1em;
	font-weight: 600;
	margin-bottom: 1rem;
}

/* typographic elements */
p {
	margin-top: 0;
	margin-bottom: 1rem;
}

small {
	font-size: 0.875em;
}

strong {
	font-weight: 600;
}

blockquote {
	font-style: italic;
	font-size: 0.9rem;
}

/* keyboard shortcuts */
kbd {
	display: inline-block;
	font-size: 0.875em;
	padding: 0 0.4em 0.1em;
	line-height: initial;
	background-color: var(--almost-bg);
	color: var(--text-color);
	border: solid 1px var(--text-color);
	border-bottom-color: var(--text-color);
	border-radius: 3px;
	box-shadow: inset 0 -1px 0 var(--text-color);
}

/* Forms (== fieldset) and children elements */
fieldset {
	padding: 0;
	border: 0;
}
label {
	display: block;
	font-size: 1em;
}
label span + input {
	margin-left: 1em;
}
label + input:not(:last-child) {
	margin-top: 0.1rem;
	margin-bottom: 0.5rem;
}
button,
input {
	font-size: 1em;
	font-family: inherit;
	color: initial;
	line-height: 1;
}
button {
	padding-left: 1em;
	padding-right: 1em;
	background: var(--bg-color);
	color: var(--text-color);
}
button[danger] {
	background-color: var(--red);
	border-color: hsl(0, 100%, 30%);
	color: var(--white);
}
button[disabled] {
	opacity: 0.6;
	text-decoration:line-through;
}
.ButtonReset {
	all: unset;
}
.underline {
	text-decoration: underline;
}

/* Shared styles between buttons and some text inputs */
button,
input[type='text'],
input[type='url'],
input[type='email'],
input[type='password'],
input[type='search'] {
	background: var(--bg-color);
	color: var(--text-color);
	border: 1px solid hsl(0, 0%, 60%);
	padding: 0 0.4em;
	min-height: 2.5em;
	transition: background-color ease-in-out 100ms, color ease-in-out 100ms;
	font-size: smaller;
}
/* Text inputs */
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='url'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='search'],
input[type='tel'],
select,
select[multiple],
textarea {
	font-family: inherit;
	width: 100%;
	max-width: 35em;
}

button {
	padding-left: 0.75em;
	padding-right: 0.75em;
}

select {
	padding-bottom: 0.2em;
}

menu select {
	padding-bottom: 0;
}

/* line separators */
hr {
	margin: 1rem 0;
	height: 0;
	border: 0;
	border-top: 2px dotted;
}

summary {
	font-weight: 600;
	padding: 0.2em 0;
	margin: 0.6em 0;
}

figure {
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
	vertical-align: bottom;
}
