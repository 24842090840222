/* menu */
menu {
	margin: 0 0 1em -0.5em;
	padding: 0;
}
menu li {
	list-style: square;
	display: flex;
	flex-flow: row wrap;
	gap: 0.2em;
}
menu li + li {
	margin-top: 0.5em;
}
menu a {
	padding: 0.2em 0.5em 0.2em;
}
menu a[aria-current="page"] {
	background-color: var(--theme);
	color: var(--bg-color);
	text-decoration: none;
}
menu a:focus [aria-current="page"] {
	outline: 1px solid var(--link-color);
}
menu fieldset {
	display: inline-block;
}
menu .SelectUserChannel {
	margin: 0 0.5em;
}

.SelectUserChannel {
	width: auto;
	/* remove browser default appearance */
	appearance: none;
	background-color: var(--bg-color);
	color: var(--theme);
	border: none;
	font-size: 1em;
	text-decoration: underline;
	text-align: center;
}
.SelectUserChannel option {
	/* so the parent select gets is width only from the active option */
	max-width: 0;
	font-weight: 400;
}

nav {
	display: flex;
	flex-wrap: wrap;
}
